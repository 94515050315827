import React from "react"
import * as Icon from "react-feather"
const navigationConfig = [
  {
    id: "home",
    title: "Dashboard",
    type: "item",
    icon: <Icon.Home size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/"
  },
  {
    id: "report",
    title: "Reports",
    type: "item",
    icon: <Icon.Activity size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/reports",
  },
  {
    id: "settings",
    title: "Settings",
    type: "item",
    icon: <Icon.Settings size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/contrast",
  },
  {
    id: "faq",
    title: "FAQ",
    type: "item",
    icon: <Icon.BookOpen size={20} />,
    permissions: ["admin", "editor"],
    navLink: "/faq",
  }
]

export default navigationConfig